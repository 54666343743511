import SectionHeading from '../../../components/SectionHeading'

const SecNgos = ({data}) => {

  return (
    <section className="partners-donation">

        <SectionHeading
            title='Our Awesome NGO Donation Partners'
            text='Here is a list of our official NGOs that we are working with to help the environment.'
        />

        <div className="container">
            <div className="row">
                {data && data.map((item , key) => (
                    <div className="col-lg-2 col-sm-6" data-aos="fade-up" key={key}>
                        <div className="partner-box">
                            <a href={item.url} > <img src={item.img} className="center-bock" /> </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
  );
}

export default SecNgos;