import {
    HomeDemo1IcoPlatforms1,
    HomeDemo1IcoPlatforms2,
    HomeDemo1IcoPlatforms3,
    HomeDemo1IcoPlatforms4,
    HomeDemo1IcoPlatforms5,
    HomeDemo1IcoPlatforms6,

    HomeDemo1Services1,
    HomeDemo1Services2,
    HomeDemo1Services3,
    HomeDemo1Services4,
    HomeDemo1Services5,
    HomeDemo1Services6,

    HomeDemo1TokenIcon1,

    HomeDemo1TeamImg1,
    HomeDemo1TeamImg2,
    HomeDemo1TeamImg3,
    HomeDemo1TeamImg4,
    HomeDemo1TeamImg5,
    
    HomeDemo1Partners1,
    HomeDemo1Partners2,
    HomeDemo1Partners3,
    HomeDemo1Partners4,
    HomeDemo1Partners5,
    HomeDemo1Partners6,
    HomeDemo1Partners7,
    HomeDemo1Partners8,
    HomeDemo1Partners9,
    HomeDemo1Partners10,
    HomeDemo1Partners11,
    HomeDemo1Partners12,
    NgoPartner1,
    NgoPartner2,
    HomeDemo1BlogImg1,
    HomeDemo1BlogImg2,
    HomeDemo1BlogImg3,
    
    PreSaleLogo,

} from '../../utils/allImgs'

export const VerticalSocial = [
    {nameIco:'fa fa-telegram', linkIco: 'https://t.me/GreenEarthMetaverse'},
    {nameIco:'fa fa-telegram', linkIco: 'https://t.me/GreenEarthMetaverse_GlobalChat'},
    {nameIco:'fa fa-telegram', linkIco: 'https://discord.gg/8ymy3sHm7F'},
    {nameIco:'fa fa-facebook', linkIco: 'https://www.facebook.com/people/Green-Earth-Metaverse/100086777821896/'},
    {nameIco:'fa fa-twitter',   linkIco: 'https://twitter.com/GreenEarthGet'},
    {nameIco:'fa fa-github',   linkIco: 'https://github.com/Green-Earth-Metaverse'},
    {nameIco:'fa fa-instagram',  linkIco: 'https://www.instagram.com/greenearthmetaverse/'},
    {nameIco:'fa fa-linkedin', linkIco: 'https://www.linkedin.com/company/green-earth-metaverse/'},
]

export const SingleCoolFact = [
    {
        img:HomeDemo1IcoPlatforms1,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms2,
        ico_check:false
    },
    {
        img:HomeDemo1IcoPlatforms3,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms4,
        ico_check:true
    },
    {
        img:HomeDemo1IcoPlatforms5,
        ico_check:false
    },
    {
        img:HomeDemo1IcoPlatforms6,
        ico_check:true
    }
]

export const service_single_content = [
    {
        img:HomeDemo1Services1,
        title:'Metaverse Game',
        description: '1. The metaverse allows users to buy and sell in game items which are used to build out the environment ...'
    },
    {
        img:HomeDemo1Services2,
        title:'Marketplace',
        description: 'Players can buy and sell resources to build their settlement. '
    },
    {
        img:HomeDemo1Services3,
        title:'DAO System',
        description: 'A DAO community where you can vote on key aspects of the Metaverse, like what abilities should be included what skins to be created?'
    },
    {
        img:HomeDemo1Services4,
        title:'Community',
        description: 'We have open community where anyone can join and vote on features or mechanics for the Metaverse. '
    },
    {
        img:HomeDemo1Services5,
        title:'Land System',
        description: 'We will be creating NFTs to allow users to purchase land for the Metaverse. These will be used to build out the environment and will be used to create skins for the game.'
    },
    {
        img:HomeDemo1Services6,
        title:'Climate Change',
        description: 'We have verified donation partners who players can send donation towards.'
    }

]

export const timelineInfo = [
    {
        timelineClass:"block block-left",
        title:'Website development and community building',
        date_from:'May 12, 2022',
        date_to:'Jun 19, 2022',
        description: 'We will be developing the website and building the community. We will be creating a discord and telegram group to allow people to join and discuss the project.'
    },
    {
        timelineClass:"block block-right mt-30",
        title:'Whitepaper and Smart contract deployment',
        date_from:'Aug 18, 2022',
        date_to:'Sep 23, 2022',
        description: 'White paper and smart contract deployment. We will be creating a whitepaper and smart contract to allow people to understand the project. We will be creating a roadmap and a whitepaper to allow people to understand the project.'
    },
    {
        timelineClass:"block block-left mt-30",
        title:'Development of the game and the marketplace',
        date_from:'Oct 08, 2022',
        date_to:'Ongoing',
        description: 'We will be developing the game and the marketplace. We will be creating a game and marketplace to allow people to buy and sell items.'
    },
    {
        timelineClass:"block block-right mt-30",
        title:'Promotional campaign',
        date_from:'Dec 2, 2022',
        date_to:'December 29, 2022',
        description: 'We will be doing a promotional campaign to allow people to understand the project.'
    }
    ,
    {
        timelineClass:"block block-left mt-30",
        title:'Token Pre-sale',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will annouce the pre-sale date and time.'
    }
    ,
    {
        timelineClass:"block block-right mt-30",
        title:'Listing on exchanges',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will be listing the token on exchanges.'
    },
    {
        timelineClass:"block block-left mt-30",
        title:'Green Earth Metaverse Land Marketplace Pre-sale',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will be doing a pre-sale for the land marketplace.'
    }
    ,
    {
        timelineClass:"block block-right mt-30",
        title:'Metaverse NFT & Marketplace Launch',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will be launching the Metaverse NFT and Marketplace.'
    }
    ,
    {
        timelineClass:"block block-left mt-30",
        title:'Competitions and weekly prize incentive launch',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will be launching competitions and weekly prize incentives.'
    }
    ,
    {
        timelineClass:"block block-right mt-30",
        title:'Donation partners integration into Metaverse Game',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will be integrating donation partners into the Metaverse Game.'
    }
    ,
    {
        timelineClass:"block block-left mt-30",
        title:'Game Beta launch',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will be launching the game alpha.'
    }

    ,
    {
        timelineClass:"block block-right mt-30",
        title:'Multiplayer testing',
        date_from:'TBC',
        date_to:'TBC',
        description: 'We will be testing the multiplayer.'
    }
]

export const ServiceBlock = [
    {
        classIco:"icon ti-mobile",
        title:"Create your own world",
        description:"Create your a vast metropolis, a small town, or a sprawling countryside. The possibilities are endless."
    },
    {
        classIco:"icon ti-widget",
        title:"Land Ownership",
        description:"Own your land and build your own world. You can sell your land to other players."
    },
    {
        classIco:"icon ti-settings",
        title:"Custom character creation",
        description:"Create your own character and customize it to your liking. You can also buy and sell skins."
    },
    {
        classIco:"icon ti-ruler-pencil",
        title:"Dynamic Weather System",
        description:"The weather system will be dynamic and will change based on the players actions."
    }
    ,
    {
        classIco:"icon ti-ruler-pencil",
        title:"Multiplayer System",
        description:"Players can battle each other in real time. The game will be a mix of RTS and RPG."
    }
    ,
    {
        classIco:"icon ti-ruler-pencil",
        title:"Economy System",
        description:"Advanced economy system where players have to manage their city or settlement."
    }
    ,
    {
        classIco:"icon ti-ruler-pencil",
        title:"NPC Population Mood System",
        description:"NPCs will have a mood system and will react to the players actions."
    }

]

export const SocialListIco = [
    {nameIco:'fa fa-telegram', link: 'https://t.me/GreenEarthMetaverse'},
    {nameIco:'fa fa-telegram', link: 'https://t.me/GreenEarthMetaverse_GlobalChat'},
    {nameIco:'fa fa-telegram', link: 'https://discord.gg/8ymy3sHm7F'},
    {nameIco:'fa fa-facebook', link: 'https://www.facebook.com/people/Green-Earth-Metaverse/100086777821896/'},
    {nameIco:'fa fa-twitter',   link: 'https://twitter.com/GreenEarthGet'},
    {nameIco:'fa fa-github',   link: 'https://github.com/Green-Earth-Metaverse'},
    {nameIco:'fa fa-instagram',  link: 'https://www.instagram.com/greenearthmetaverse/'},
    {nameIco:'fa fa-linkedin', link: 'https://www.linkedin.com/company/green-earth-metaverse/'},
]

export const FQAInfo = [
    {
        text:'What are the objectives of project?',
        ID:'Q1',
        description: 'The main objective of this project is to create a Metaverse game where players can buy and sell in game items which are used to build out the environment. Players have to unite to overcome climate change. The story of the game is as follows Earth has been ravaged and you as the player must work towards a common goal of fixing the planet. Each player has daily tasks and goals to achieve. Players can battle each other like RTS in battle for certain parts of the map. Players can buy and sell resources to build their settlement. A DAO community where you can vote on key aspects of the Metaverse, like what abilities should be included what skins to be created? We have open community where anyone can join and vote on features or mechanics for the Metaverse. We will be creating NFTs to allow users to purchase land for the Metaverse. These will be used to build out the environment and will be used to create skins for the game. We have verified donation partners who players can send donation towards.'
    },
    {
        text:'What is Token Sale and pre-sale?',
        ID:'Q2',
        description: ''
    },
    {
        text:'What is the pre-sale start date?',
        ID:'Q3',
        description: 'The pre-sale dates will be announced soon.'
    },
    {
        text:'how may I take part in pre-sale?',
        ID:'Q4',
        description: 'The pre-sale information will be announced soon.'
    },
]

export const DocElementTitle = [
    {
        title:'WhitePaper',
        link: 'https://greenearthmetaverse.com/assets/whitepaper.b4830e41.pdf'
    },
    {
        title:'OnePager'
    },
    {
        title:'Rating Review'
    },
    {
        title:'Marketing Plan'
    },
]

export const TokenText = [
    {
        text:'The GET token is used in the Metaverse app to buy resources and as unit of exchange with other players.',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'It is also used to buy and sell items in the marketplace.',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'There is total by and sell tax of %5 on all transactions.',
        img:HomeDemo1TokenIcon1
    },
    {
        text:'The tax is used to develop the Metaverse and to pay for better services.',
        img:HomeDemo1TokenIcon1
    },
]

export const MissionData = [
    {
        icoName:"ti-shine",
        title:"Quality"
    },
    {
        icoName:"ti-ruler-pencil",
        title:"Creativity"
    },
    {
        icoName:"ti-heart",
        title:"Reliability"
    },
]

export const TeamMember = [
    {
        img:HomeDemo1TeamImg1,
        title:'Junaid',
        text:'Lead Developer',
        linkLinkedin:'',

    },
    {
        img:HomeDemo1TeamImg2,
        title:'Shahrukh',
        text:'Chief Operating Officer',
        linkLinkedin:'',
    },
    {
        img:HomeDemo1TeamImg3,
        title:'Addy',
        text:'Executive Officer',
        linkLinkedin:'',

    },
    {
        img:HomeDemo1TeamImg4,
        title:'Dean',
        text:'Technology Advisor',
        linkLinkedin:'',

    },
    {
        img:HomeDemo1TeamImg5,
        title:'Emmett',
        text:'Advisor',
        linkLinkedin:'',

    },
]

export const PartnersData = [
    {img:HomeDemo1Partners1},
    {img:HomeDemo1Partners2},
    {img:HomeDemo1Partners3},
    {img:HomeDemo1Partners4},
    {img:HomeDemo1Partners5},
    {img:HomeDemo1Partners6},
    {img:HomeDemo1Partners7},
    {img:HomeDemo1Partners8},
    {img:HomeDemo1Partners9},
    {img:HomeDemo1Partners10},
    {img:HomeDemo1Partners11},
    {img:HomeDemo1Partners12}
]

export const NgosData = [
    {img:NgoPartner1, url: "https://savingnature.com/"},
    {img:NgoPartner2, url: "https://trees.org/"}
]


export const BlogPost = [
    {img:HomeDemo1BlogImg1},
    {img:HomeDemo1BlogImg2},
    {img:HomeDemo1BlogImg3}
]
