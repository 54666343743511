import SectionHeading from '../../../components/SectionHeading'
import SecVideoArea from './SecVideoArea'

const SecDemoVideo = ({img}) => {

  return (
    <section className="demo-video section-before section-padding-100">
        <div className="container">
            <SectionHeading
                title='Watch our demo video'
                text='This is footage of alpha version of the game prototype that we have developed. We are working on the full alpha version of the game and it will be released soon.'
            />
            <SecVideoArea img={img} />
        </div>
    </section>
  );
}

export default SecDemoVideo;