const SecIco = ({logo}) => {
	return(
        <div className="col-12 col-md-5">
          <div className="footer-copywrite-info">
            {/* Copywrite */}
            <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
              <div className="footer-logo">
                <a href="#"><img src={logo} alt="logo" /></a>
              </div>
              <p>Join our official community channels and keep up to date with all things Green Earth Metaverse.</p>
            </div>
            {/* Social Icon */}
            <div className="footer-social-info wow fadeInUp" data-wow-delay="0.4s">
              <a href="https://discord.com/invite/8ymy3sHm7F"><i className="fa fa-discord" aria-hidden="true" /></a>
              <a href="https://twitter.com/GreenEarthGet"> <i className="fa fa-twitter" aria-hidden="true" /></a>
              <a href="https://www.tiktok.com/@greenearthmetaverse"><i className="fa-brands tiktok" aria-hidden="true" /></a>
              <a href="https://www.instagram.com/greenearthmetaverse/ "><i className="fa fa-instagram" aria-hidden="true" /></a>
              <a href="https://www.linkedin.com/company/green-earth-metaverse/"><i className="fa fa-linkedin" aria-hidden="true" /></a>
            </div>
          </div>
        </div>
	)
}

export default SecIco