function SecWelcomeContent({img}){
  return(
      <div className="welcome-content">
          <div className="promo-section">
              <div className="integration-link">
                  <span className="integration-icon">
                      <img  width="24" height="24" alt="" />
                  </span>
                  <span className="integration-text">Lets build a Greener and Safer Future!</span>
              </div>
          </div>
          <h1>GREEN EARTH METAVERSE</h1>
          <p>
          Create, Build and Explore!
          Green Earth Metaverse enables the bridging of virtual in-app metaverse player
          activities with climate change initiatives enabling a real world impact on our world.

          </p>
  

          {/* <p className="h2">
          GREEN EART METAVERSE TOKEN (GET) is a BEP-20 token on the Binance Smart Chain.
          BUY NOW on PancakeSwap!
          </p> */}


          {/* <div className="dream-btn-group">
              <a href="https://pancakeswap.finance/swap?outputCurrency=0x296c39ed44eae6bfc9b4ed62ba029ec6fb8102bf" className="btn dream-btn mr-3">Buy Now</a>
              <a href="mailto:info@greenearthmetaverse.com" className="btn dream-btn">Contact Us</a>
          </div> */}


      </div>
  )
}

export default SecWelcomeContent