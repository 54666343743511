import {HomeDemo1Dollar} from '../../../utils/allImgs'

import SecWelcomeContent from './SecWelcomeContent'

const DIV = () => <div className="dream-blip blip1"></div>

const SecWelcomeArea = () => {

  return (
    <section className="welcome_area clearfix dzsparallaxer auto-init ico fullwidth" data-options={{direction: "normal"}} id="home">
        <div className="divimage dzsparallaxer--target Home1WelcomeAreaIMG"></div>

        <div className="hero-content">
            {Array(4).fill().map((item , key) => (
                <DIV />
              ))}

            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-12 col-lg-6 col-md-12">
                        <SecWelcomeContent img={HomeDemo1Dollar} />
                    </div>
                    <div className="col-12 col-lg-6 col-md-12">
                    {/* <iframe
  src="https://pancakeswap.finance/swap?outputCurrency=0x296c39ed44eae6bfc9b4ed62ba029ec6fb8102bf"
  height="660px"
  width="100%"
  style={{border: "0", margin: "0 auto", display: "block", borderRadius: "10px", maxWidth: "600px", minWidth: "300px"}}
  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
/> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default SecWelcomeArea;